/* src/components/ChatAssistant.css */

@media (max-width: 500px) {
    .chat-box {
        width: calc(100vw - 40px) !important;
    }
}

.chat-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #000;
    color: white;
    border-radius: 10px;
    width: 48px;
    height: 48px;
    font-size: 24px;
    cursor: pointer;
    box-shadow: 0px 0px 0px 1px rgba(17, 17, 17, 0.04), 0px 1px 2px 0px rgba(17, 17, 17, 0.06);
}

.chat-box {
    position: fixed;
    bottom: 80px;
    right: 20px;
    width: 456px;
    height: 70vh;
    max-height: 700px;
    min-height: 450px;
    background-color: white;
    border: 1px solid #F3F3F3;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 2px 5px 0px rgba(27, 28, 29, 0.4);}

.chat-header {
    color: black;
    padding: 16px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
}

/*.message-container {
}*/

.chat-messages {
    flex: 1;
    padding: 16px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.user-message {
    text-align: left;
}

.assistant-message {
    text-align: left;
}

.chat-input {
    display: flex;
    padding: 16px;
    min-height:108px;
    height: auto;
    flex-direction: column;
    gap: 12px;
    border-top: 1px solid #F3F3F3;
}

.chat-input input {
    flex: 1;
    padding: 10px;
    border-radius: 4px;
}

.chat-input button {
    margin-left: 10px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}
.kahuna-gradient-text {
    background: linear-gradient(90deg, #7C2DFC 0%, #FF58EC 49.88%, #FF9558 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-feature-settings: 'ss11' on, 'cv09' on, 'liga' off, 'calt' off;
    font-family: "Aeonik Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.084px;    
    display: flex;
    align-items: center;
}
.user-capital-logo {
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-family: Inter;
    font-weight: 500;
    color: #000;
    line-height: 20px;
    letter-spacing: -0.084px;
    background: url('../../../../public/account_icons/user-background.svg') no-repeat center;
    border-radius: 100px;
}
.send-email {
    font-size: 14px;
    font-family: Inter;
    line-height: 20px;
    letter-spacing: -0.084px;
    color: #000;   
}
